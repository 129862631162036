export interface CategoryIcons {
  id: number;
  name: string;
  icon: string;
}
export interface ExtrasCard {
  id: string;
  title: string;
  description: string;
  fullContent?: string;
  image?: string;
  url?: string;
  lat?: string;
  lng?: string;
  region?: string;
  category?: string;
  categoryIcons?: CategoryIcons[];
  activity?: string;
  regionId?: string;
}

export interface VisitIdahoDetails {
  id: number;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
  excerpt: {
    rendered: string;
  };
  link: string;
  adventure_category: number[];
  region: number[];
  season: number[];
  budget: number[];
  activity_level: number[];
  gallery_images?: GalleryImage[];
  things_to_know: string;
}

export interface GalleryImage {
  id: number;
  alt: string;
  caption: string;
  url: string;
}

export interface InAppExtrasProps {
  // extras: ExtrasCard[];
  title?: string;
  showingExtras: (state: boolean) => void;
  lat: number;
  lng: number;
}

export interface VisitIdahoExtras {
  id: number;
  title: string;
  url: string;
  excerpt: string;
  image: string;
  lat: string;
  lng: string;
  city: string;
  state: string;
  region: string;
  region_id: string;
  category_icons?: CategoryIcons[];
  primary_category: string;
}

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

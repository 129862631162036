import React, { useState } from "react";
import Swiper from "react-id-swiper";
import { InAppExtrasCard } from "./InAppExtrasCard";
import {
  CloseExtrasButton,
  GlobalStyle,
  SwiperContainer,
  LeftArrow,
  RightArrow,
  Title,
  ExploreButton,
  DialogOverlay,
  Dialog,
  CloseButton,
  ImageContainer,
  DialogContent,
  Section,
  RegionLink,
  ActionButton,
  LoadingIndicator,
  ActivitiesContainer,
  ExtrasContainer,
  InAppExtrasCardWrapper,
  ActivitiesWrapper,
  ActivitiesText,
  InAppExtrasContent,
  ActivitiesImageContainer,
  DialogTitle,
  ThingsToKnow,
  HeaderText,
  ThingsToKnowContent,
  UniqueSymbol,
} from "./styled";
import {
  InAppExtrasProps,
  ExtrasCard,
  VisitIdahoDetails,
  VisitIdahoExtras,
  calculateDistance,
} from "./in-app-extras-interfaces";
import Carousel from "./Carousel";

const InAppExtras: React.FC<InAppExtrasProps> = ({
  showingExtras,
  title,
  lat,
  lng,
}) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const params = {
    slidesPerView: 3,
    freeMode: true,
    spaceBetween: 16,
    containerClass: "swiper-container full-height",
    height: "100%",
    autoHeight: true,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      480: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 16,
      },
    },
  };

  const [selectedExtras, setSelectedExtras] = useState<ExtrasCard | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [extrasDetails, setExtrasDetails] = useState<VisitIdahoDetails | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(loading);
  const [extras, setExtras] = React.useState<ExtrasCard[]>([]); // State to store the list of extras

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();

      // We need to update these after a slight delay to ensure the transition is complete
      setTimeout(() => {
        if (swiper) {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }
      }, 300);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();

      // We need to update these after a slight delay to ensure the transition is complete
      setTimeout(() => {
        if (swiper) {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }
      }, 300);
    }
  };

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, match => match.toUpperCase());
  };

  const fetchExtrasDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://visitidaho.org/wp-json/wp/v2/adventure/${id}`
      );
      const data = await response.json();
      setExtrasDetails(data);
    } catch (error) {
      setExtrasDetails(null);
      console.error("Error fetching extras details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExtrasClick = async (extrasId: string) => {
    const extra = extras.find(a => a.id === extrasId);
    setSelectedExtras(extra || null);
    if (extra) {
      await fetchExtrasDetails(extrasId);
    }
  };

  const handleClose = () => {
    setSelectedExtras(null);
    setExtrasDetails(null);
  };

  // Function to convert VisitIdahoExtras object to ExtrasCard type
  const convertToExtrasCard = React.useCallback(
    (visitIdahoExtras: VisitIdahoExtras): ExtrasCard => ({
      id: visitIdahoExtras.id.toString(),
      title: visitIdahoExtras.title,
      description: visitIdahoExtras.primary_category,
      image: visitIdahoExtras.image,
      fullContent: visitIdahoExtras.excerpt,
      url: visitIdahoExtras.url,
      lat: visitIdahoExtras.lat,
      lng: visitIdahoExtras.lng,
      region: visitIdahoExtras.region,
      regionId: visitIdahoExtras.region_id,
      categoryIcons: visitIdahoExtras.category_icons,
    }),
    []
  );

  // Function to fetch nearby extras based on latitude and longitude
  const fetchNearbyExtras = React.useCallback(
    async (latitude: number, longitude: number) => {
      const filterByDistance = (
        extras: VisitIdahoExtras[],
        userLat: number,
        userLng: number
      ) => {
        const extrasWithDistance = extras.map(extra => ({
          ...extra,
          distance: calculateDistance(
            userLat,
            userLng,
            parseFloat(extra.lat),
            parseFloat(extra.lng)
          ),
        }));

        // Filter extras within 15 km and sort them by distance
        const nearbyExtras = extrasWithDistance
          .filter(extra => extra.distance <= 15)
          .sort((a, b) => a.distance - b.distance)
          .slice(0, 15);

        return nearbyExtras;
      };

      try {
        setLoading(true);
        const response = await fetch(
          "https://visitidaho.org/wp-json/vita_route/adventures"
        );
        const initialJSON = await response.json();
        const data: VisitIdahoExtras[] = initialJSON;
        const nearbyExtras = filterByDistance(data, latitude, longitude);
        const formattedExtras = nearbyExtras.map(convertToExtrasCard);
        setExtras(formattedExtras);
      } catch (error) {
        setExtras([]);
      } finally {
        setLoading(false);
      }
    },
    [convertToExtrasCard]
  );

  // Effect hook to fetch nearby extras when exhibitData changes
  React.useEffect(() => {
    if (lat && lng) {
      fetchNearbyExtras(lat, lng);
    }
  }, [fetchNearbyExtras, lat, lng]);

  const handleSwiperInit = (swiperInstance: any) => {
    if (swiperInstance) {
      setSwiper(swiperInstance);
      setIsBeginning(true);
      setIsEnd(
        swiperInstance.slides.length <= swiperInstance.params.slidesPerView
      );

      swiperInstance.on("slideChange", () => {
        setIsBeginning(swiperInstance.isBeginning);
        setIsEnd(swiperInstance.isEnd);
      });
    }
  };

  return (
    extras.length > 0 && (
      <GlobalStyle>
        {isVisible ? (
          <ExtrasContainer>
            <CloseExtrasButton
              onClick={() => {
                showingExtras(false);
                setIsVisible(false);
              }}
              aria-label="Close recommendations"
            >
              <UniqueSymbol>x</UniqueSymbol>
            </CloseExtrasButton>
            {title && <Title>{title}</Title>}
            <SwiperContainer title={title}>
              <Swiper
                {...params}
                getSwiper={handleSwiperInit}
                shouldSwiperUpdate
              >
                {extras.map(extra => (
                  <InAppExtrasCardWrapper key={extra.id}>
                    <InAppExtrasCard
                      onClick={() => handleExtrasClick(extra.id)}
                      iconSrc={
                        extra?.categoryIcons && extra?.categoryIcons[0]?.icon
                      }
                      title={extra.title}
                      paragraph={extra.description}
                    />
                  </InAppExtrasCardWrapper>
                ))}
              </Swiper>
              <LeftArrow
                onClick={goPrev}
                disabled={isBeginning}
                aria-label="Previous slide"
              >
                <UniqueSymbol>&lt;</UniqueSymbol>
              </LeftArrow>
              <RightArrow
                onClick={goNext}
                disabled={isEnd}
                aria-label="Next slide"
              >
                <UniqueSymbol>&gt;</UniqueSymbol>
              </RightArrow>
            </SwiperContainer>
          </ExtrasContainer>
        ) : (
          <ExploreButton
            onClick={() => {
              showingExtras(true);
              setIsVisible(true);
            }}
          >
            Explore More
          </ExploreButton>
        )}

        {selectedExtras && (
          <DialogOverlay
            onClick={e => e.target === e.currentTarget && handleClose()}
          >
            <Dialog>
              <CloseButton onClick={handleClose} aria-label="Close details">
                <UniqueSymbol>x</UniqueSymbol>
              </CloseButton>

              <ImageContainer>
                <img
                  src={
                    selectedExtras.image ??
                    "https://viiision.com/_assets/media/0b63ac034d5748d4a13a3c3710aaf1ee.jpg"
                  }
                  alt={selectedExtras.title}
                />
              </ImageContainer>

              <DialogContent>
                {isLoading ? (
                  <LoadingIndicator />
                ) : (
                  <Section>
                    <DialogTitle>{selectedExtras.title}</DialogTitle>

                    {extrasDetails?.adventure_category &&
                      extrasDetails.adventure_category.length > 0 && (
                        <>
                          <ActivitiesText>Activities</ActivitiesText>

                          <ActivitiesWrapper>
                            {selectedExtras?.categoryIcons &&
                              selectedExtras.categoryIcons.map(
                                (icon, index) => (
                                  <ActivitiesContainer
                                    key={index}
                                    title={icon.name}
                                  >
                                    <ActivitiesImageContainer>
                                      <img src={icon.icon} alt={icon.name} />
                                    </ActivitiesImageContainer>
                                  </ActivitiesContainer>
                                )
                              )}
                          </ActivitiesWrapper>
                        </>
                      )}

                    {extrasDetails?.region && extrasDetails.region.length > 0 && (
                      <>
                        <HeaderText>Region</HeaderText>
                        <RegionLink href="#">
                          {selectedExtras.region &&
                            capitalizeWords(selectedExtras.region)}
                        </RegionLink>
                      </>
                    )}

                    <InAppExtrasContent
                      dangerouslySetInnerHTML={{
                        __html:
                          extrasDetails?.content.rendered ||
                          selectedExtras.fullContent ||
                          "",
                      }}
                    />

                    {extrasDetails?.gallery_images && (
                      <InAppExtrasContent>
                        <Carousel items={extrasDetails.gallery_images} />
                      </InAppExtrasContent>
                    )}

                    {extrasDetails?.things_to_know && (
                      <>
                        <ThingsToKnow>Things to Know</ThingsToKnow>

                        <ThingsToKnowContent
                          dangerouslySetInnerHTML={{
                            __html: extrasDetails.things_to_know,
                          }}
                        />
                      </>
                    )}

                    <ActionButton
                      href={`https://www.google.com/maps/dir/?api=1&destination=${selectedExtras.lat},${selectedExtras.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get Directions
                    </ActionButton>
                  </Section>
                )}
              </DialogContent>
            </Dialog>
          </DialogOverlay>
        )}
      </GlobalStyle>
    )
  );
};

export default InAppExtras;

import React, { useState } from "react";
import Swiper from "react-id-swiper";
import { ChevronLeft, ChevronRight, X } from "react-feather";
import {
  CarouselContainer,
  CloseButton,
  DialogOverlay,
  ImageView,
  LeftArrow,
  PreviewDialog,
  PreviewDialogContent,
  PreviewImageContainer,
  RightArrow,
  ThumbnailImageView,
} from "./styled";
import { GalleryImage } from "./in-app-extras-interfaces";

interface CarouselProps {
  items: GalleryImage[];
  slidesPerView?: number;
  spaceBetween?: number;
}

const Carousel: React.FC<CarouselProps> = ({
  items,
  slidesPerView = 3,
  spaceBetween = 16,
}) => {
  const [swiper, setSwiper] = useState<any>(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Default params for Swiper
  const params = {
    slidesPerView,
    freeMode: true,
    spaceBetween,
    containerClass: "swiper-container full-height",
    height: "100%",
    autoHeight: true,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
      480: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
    },
  };

  // Gallery image to React.ReactNode[]
  const galleryImages = items.map(item => {
    return (
      <PreviewImageContainer key={item.id}>
        <ThumbnailImageView
          src={item.url}
          alt={item.alt}
          onClick={() => {
            setSelectedImage(item.url || "");
          }}
        />
      </PreviewImageContainer>
    );
  });

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
      setTimeout(() => {
        if (swiper) {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }
      }, 300);
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
      setTimeout(() => {
        if (swiper) {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }
      }, 300);
    }
  };

  const handleSwiperInit = (swiperInstance: any) => {
    if (swiperInstance) {
      setSwiper(swiperInstance);
      setIsBeginning(true);
      setIsEnd(false);

      swiperInstance.on("slideChange", () => {
        setIsBeginning(swiperInstance.isBeginning);
        setIsEnd(swiperInstance.isEnd);
      });
    }
  };
  const handleClose = () => {
    setSelectedImage("");
  };

  return (
    <CarouselContainer>
      <Swiper {...params} getSwiper={handleSwiperInit} shouldSwiperUpdate>
        {galleryImages.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </Swiper>
      <LeftArrow
        onClick={goPrev}
        disabled={isBeginning}
        aria-label="Previous slide"
      >
        <ChevronLeft size={20} />
      </LeftArrow>
      <RightArrow onClick={goNext} disabled={isEnd} aria-label="Next slide">
        <ChevronRight size={20} />
      </RightArrow>

      {selectedImage && (
        <DialogOverlay
          onClick={e => e.target === e.currentTarget && handleClose()}
        >
          <PreviewDialog>
            <CloseButton onClick={handleClose} aria-label="Close details">
              <X size={20} />
            </CloseButton>
            <PreviewDialogContent>
              <ImageView src={selectedImage} />
            </PreviewDialogContent>
          </PreviewDialog>
        </DialogOverlay>
      )}
    </CarouselContainer>
  );
};

export default Carousel;

import { device } from "src/helpers/responsive";
import styled from "styled-components";

export const ExtrasContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 16px;
  right: 16px;
  max-width: 960px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.12);
  z-index: 50;
  padding: 16px 20px;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  border-top: 3px solid #c08267;

  ${device.mobile} {
    padding: 20px 24px;
    border-radius: 24px 24px 0px 0px;
    width: calc(100% - 48px);
  }

  ${device.desktop} {
    padding: 24px 28px;
    border-radius: 28px 28px 0px 0px;
    width: calc(100% - 64px);
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: #c08267;
  padding: 0;
  font-family: Albra, sans-serif;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 40px;
    height: 3px;
    background: #e6a27c;
  }

  ${device.mobile} {
    font-size: 18px;
    margin: 0 0 20px 0;
  }

  ${device.desktop} {
    font-size: 22px;
    margin: 0 0 22px 0;
  }
`;

export const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  backdrop-filter: blur(3px);
  transition: opacity 0.3s ease;
`;

export const Dialog = styled.div`
  background: #eee9e3;
  border-radius: 28px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 550px;
  max-height: 85vh;
  position: relative;
  overflow: auto;
  margin-bottom: 20px;
  animation: dialogFadeIn 0.3s ease-out;

  @keyframes dialogFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  ${device.tabletSmAsMax} {
    border-radius: 24px;
    width: 95%;
  }
`;

export const DialogTitle = styled.h1`
  font-size: 34px;
  font-weight: 600;
  margin: 0px;
  color: #1b1c20;
  font-family: Albra, sans-serif;
  line-height: 41px;
  padding: 0;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 260px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Section = styled.div`
  padding: 24px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  left: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  color: #333;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #ffffff;
  }

  ${device.tabletSmAsMax} {
    top: 16px;
    left: 16px;
  }
`;

export const DialogContent = styled.div`
  background: #eee9e3;
  font-family: europa, sans-serif;
`;

export const CloseExtrasButton = styled.button`
  position: absolute;
  top: -20px;
  right: 20px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
`;

export const UniqueSymbol = styled.div`
  display: flex;
  align-items: center;
  color: #000;
`;

export const LeftArrow = styled.button`
  left: -12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.2s ease;
  color: #333;

  &:hover {
    background: #ffffff;
    transform: translateY(-50%) scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${device.tabletSmAsMax} {
    width: 36px;
    height: 36px;
  }
`;

export const RightArrow = styled.button`
  right: -12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.2s ease;
  color: #333;

  &:hover {
    background: #ffffff;
    transform: translateY(-50%) scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${device.tabletSmAsMax} {
    width: 36px;
    height: 36px;
  }
`;

export const ExploreButton = styled.button`
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(calc(480px - 50%));
  padding: 14px 24px;
  background: #c08267;
  border-radius: 24px;
  border: none;
  box-shadow: 0 4px 12px rgba(192, 130, 103, 0.3);
  font-weight: 600;
  cursor: pointer;
  z-index: 49;
  transition: all 0.2s;
  color: white;
  font-family: Albra, sans-serif;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    stroke-width: 2.5px;
  }

  @media (max-width: 1020px) {
    right: 20px;
    left: auto;
    transform: none;
  }

  &:hover {
    background: #e6a27c;
    @media (min-width: 1021px) {
      transform: translateX(calc(480px - 50%)) translateY(-2px);
    }
    @media (max-width: 1020px) {
      transform: translateY(-2px);
    }
  }
`;

export const SwiperContainer = styled.div`
  margin-top: ${props => (props.title ? "16px" : "0")};
  overflow: hidden;
  position: relative;

  .swiper-container {
    overflow: visible;
    padding-bottom: 12px;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: 100%;
    display: flex;
  }
`;

export const ActionButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: white;
  border: none;
  border-radius: 15px;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 24px;
  background: #b7562d;
  text-decoration: none;
  text-align: center;
  font-family: europa, sans-serif;
`;

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;

  &:after {
    content: "";
    width: 32px;
    height: 32px;
    border: 3px solid #e0e0e0;
    border-radius: 50%;
    border-top-color: #c08267;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const GlobalStyle = styled.div`
  .swiper-container.full-height,
  .swiper-container.full-height .swiper-wrapper,
  .swiper-container.full-height .swiper-slide {
    height: 100%;
  }
`;

export const HeaderText = styled.h3`
  font-family: "europa", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #1b1c20;
  line-height: 19px;

  margin: 0px;
  margin-top: 22px;
  padding-top: 5px;
`;

export const RegionLink = styled.a`
  color: #2f6062;
  text-decoration: none;
  display: block;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 26px;
  margin-top: 5px;
  font-family: "europa", sans-serif;
`;

export const ActivitiesWrapper = styled.div`
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ActivitiesContainer = styled.div`
  background-color: rgb(192, 110, 74);
  height: 31px;
  width: 31px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ActivitiesImageContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 0px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 20px;
    width: 20px;
    border-radius: 0px;
    margin: 0px;
    padding: 0px;
  }
`;

export const ActivitiesText = styled.h3`
  font-family: "europa", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #1b1c20;
  line-height: 19px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0px;
`;

export const InAppExtrasCardWrapper = styled.div`
  height: 100%;
`;

export const InAppExtrasCardContainer = styled.div`
  background-color: #eee9e3;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  font-family: "Albra", sans-serif;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 151px;
  width: 188px;
  padding: 8px 0px;
  overflow: hidden;
  position: relative;
  flex: 1;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
  }

  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #c08267, #e6a27c);
  }
`;

export const InAppExtrasCardImageContainer = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InAppExtrasCardIcon = styled.div`
  background-color: #c67754;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(192, 130, 103, 0.3);
`;

export const InAppExtrasCardImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const InAppExtrasCardContent = styled.div`
  text-align: center;
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
`;

export const InAppExtrasCardTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 22px;
  color: #1b1c20;
  font-family: "Albra", sans-serif;
`;

export const InAppExtrasCardDescription = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  font-family: "europa", sans-serif;
`;

export const AddBottomSpacing = styled.div`
  height: 210px;
`;

export const InAppExtrasContent = styled.div`
  font-family: "europa", sans-serif;
  color: #1b1c20;
  line-height: 19px;
  margin-bottom: 0px;
`;

export const ThingsToKnow = styled.div`
  font-family: "europa", sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: #2f6062;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const ThingsToKnowContent = styled.div`
  font-family: "europa", sans-serif;
  font-size: 16px;
  line-height: 19px;
  li {
    margin-bottom: 0px;
  }
`;

export const CarouselContainer = styled.div`
  position: relative;
  height: 150px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  .swiper-container {
    overflow: visible;
    padding-bottom: 12px;
  }

  .swiper-wrapper {
    align-items: stretch;
  }

  .swiper-slide {
    height: 100%;
    display: flex;
  }
`;

export const ThumbnailImageView = styled.img`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  object-fit: cover;
  border-radius: 24px !important;
`;

export const ImageView = styled.img`
  width: 100%;
  margin: 0px;
  padding: 0px;
`;

export const PreviewDialog = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  position: relative;
  overflow: hidden;
  animation: dialogFadeIn 0.3s ease-out;

  @keyframes dialogFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const PreviewDialogContent = styled.div`
  overflow-y: auto;
  font-family: europa, sans-serif;
  padding-bottom: 16px;
`;

export const PreviewImageContainer = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

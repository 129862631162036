import React from "react";
import {
  InAppExtrasCardContainer,
  InAppExtrasCardContent,
  InAppExtrasCardDescription,
  InAppExtrasCardIcon,
  InAppExtrasCardImage,
  InAppExtrasCardImageContainer,
  InAppExtrasCardTitle,
} from "./styled";

interface InAppExtrasCardProps {
  iconSrc: string;
  title: string;
  paragraph: string;
  onClick: () => void;
}

export const InAppExtrasCard: React.FC<InAppExtrasCardProps> = ({
  iconSrc,
  title,
  paragraph,
  onClick,
}) => {
  return (
    <InAppExtrasCardContainer onClick={onClick}>
      <InAppExtrasCardImageContainer>
        <InAppExtrasCardIcon>
          <InAppExtrasCardImage src={iconSrc} alt="Icon" />
        </InAppExtrasCardIcon>
      </InAppExtrasCardImageContainer>
      <InAppExtrasCardContent>
        <InAppExtrasCardTitle>
          {title.slice(0, 22) + (title.length > 22 ? "..." : "")}
        </InAppExtrasCardTitle>
        <InAppExtrasCardDescription>{paragraph}</InAppExtrasCardDescription>
      </InAppExtrasCardContent>
    </InAppExtrasCardContainer>
  );
};

import { device } from "src/helpers/responsive";
import styled from "styled-components";

// Modal content component
export const ModalContent = styled.div`
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  min-height: 280px;
  min-width: 280px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

// Modal backdrop component
export const ModalBackdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const CloseButton = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #888;

  &:hover {
    color: #333;
  }
`;

export const FABWrapper = styled.div`
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(calc(480px - 50%));
  width: 56px;
  height: 56px;
  background: #d4dffd;
  border-radius: 50%;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;

  @media (max-width: 1020px) {
    right: 20px;
    left: auto;
    transform: none;
  }

  &:hover {
    @media (min-width: 1021px) {
      transform: translateX(calc(480px - 50%)) translateY(-2px);
    }
    @media (max-width: 1020px) {
      transform: translateY(-2px);
    }
  }
`;

export const RotateOnHover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(45deg);
  }
`;

export const FABContainer = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  max-width: ${props => props.theme.maxContentWidth || "1200px"};
  margin: 0 auto;
  z-index: 50;
  width: calc(100% - 32px);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;

  ${device.tabletSmAsMax} {
    bottom: 24px;
    width: calc(100% - 48px);
  }

  ${device.desktop} {
    bottom: 32px;
    width: calc(100% - 64px);
  }
`;
